import "./Main.css";
import React from "react";

function Main(props) {
  const [isX, setX] = React.useState("");
  const [isY, setY] = React.useState("");
  return (
    <section className={`${props.class} main`}>
      <div className="main__box">
        <h1 className="main__title">{props.title}</h1>
        <p className="main__subtitle">{props.subtitle}</p>
        <button
          className="main__button autoblick circleflash"
          onClick={() => props.setOpenPopup(true)}
          onMouseEnter={(e) => {
            setX(e.screenX - e.currentTarget.getBoundingClientRect().x);
            setY(e.screenY - e.currentTarget.getBoundingClientRect().y);
          }}
        >
          <p className="button__text">{props.button}</p>
          <span style={{ top: isY + "px", left: isX + "px" }}></span>
        </button>
      </div>
    </section>
  );
}

export default Main;

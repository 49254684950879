import "./App.css";
import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import ErrorPage from "../ErrorPage/ErrorPage";
import { Helmet } from "react-helmet";
import Referal from "../Referal/Referal";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Popup from "../Popup/Popup";
import owner from "../../images/owner.png";
import owneravif from "../../images/owner.avif";
import ownerwebp from "../../images/owner.webp";
import { useLocation } from "react-router";

const Home = React.lazy(() => import("../Home/Home"));
const Form = React.lazy(() => import("../Form/Form"));
const Thanks = React.lazy(() => import("../Thanks/Thanks"));

function App() {
  const location = useLocation();
  const [isOpenPopupForm, setOpenPopupForm] = React.useState(false);
  const [isOpenPopupImg, setOpenPopupImg] = React.useState(false);
  const [isOpenPopupArticle, setOpenPopupArticle] = React.useState(false);
  const [isPopupImg, setPopupImg] = React.useState();
  const [isTitle, setTitle] = React.useState("");
  const [isId, setId] = React.useState("");
  const [isDate, setDate] = React.useState("");
  const [isArticle, setArticle] = React.useState([]);
  const [isThanks, setThanks] = React.useState(false);
  // Hook
  const useOnScreen = (options) => {
    const ref = React.useRef();
    const [visible, setVisible] = React.useState(false);
    React.useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
        }
      }, options);
      if (ref.current) {
        observer.observe(ref.current);
      }
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref, options]);
    return [ref, visible];
  };
  function isOpen(s) {
    setOpenPopupImg(true);
    setPopupImg({ src: s.src, webp: s.webp, avif: s.avif });
  }
  function onClose() {
    setOpenPopupForm(false);
    setOpenPopupImg(false);
    setOpenPopupArticle(false);
  }
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
  });
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
    });
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);
  return (
    <div className="app">
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route
            path="/"
            element={
              <Home
                isOpenPopupForm={isOpenPopupForm}
                setOpenPopupForm={setOpenPopupForm}
                isOpenPopupImg={isOpenPopupImg}
                setOpenPopupImg={setOpenPopupImg}
                isOpenPopupArticle={isOpenPopupArticle}
                setOpenPopupArticle={setOpenPopupArticle}
                isPopupImg={isPopupImg}
                setPopupImg={setPopupImg}
                isTitle={isTitle}
                setTitle={setTitle}
                isId={isId}
                setId={setId}
                isDate={isDate}
                setDate={setDate}
                isArticle={isArticle}
                setArticle={setArticle}
                isThanks={isThanks}
                setThanks={setThanks}
                isOpen={isOpen}
                onClose={onClose}
                dimensions={dimensions}
                setDimensions={setDimensions}
                handleResize={handleResize}
                useOnScreen={useOnScreen}
              >
                <Helmet>
                  <meta
                    name="description"
                    content="Студия интернет рекламы Владимира Попова. Бесплатный аудит текущей рекламной кампании. Кейсы и полезные видео для бизнеса."
                  />
                  <title>
                    Студия интернет рекламы "Владимира Попова". Настройка
                    контекстной рекламы в Яндекс Директ, Вконтакте,
                    Одноклассниках и Яндекс ДЗЕН
                  </title>
                  <link rel="canonical" href="https://popov.media/" />
                  <meta name="robots" content="index,follow" />
                  <meta name="keywords" content="" />
                  <meta
                    property="og:title"
                    content='Студия интернет рекламы "Владимира Попова". Настройка контекстной рекламы
      в Яндекс Директ, Вконтакте, Одноклассниках и Яндекс ДЗЕН'
                  />
                  <meta
                    property="og:description"
                    content="Студия интернет рекламы Владимира Попова. Бесплатный аудит текущей рекламной кампании. Кейсы и полезные видео для бизнеса."
                  />
                </Helmet>
              </Home>
            }
          >
            <Route
              path="/thank-you"
              element={
                isThanks ? (
                  <Thanks Helmet={Helmet}>
                    <Helmet>
                      <meta
                        name="description"
                        content="Спасибо за заявку, мы свяжемся с Вами в ближайшее время!"
                      />
                      <title>Спасибо</title>
                      <link rel="canonical" href="https://popov.media/" />
                      <meta name="robots" content="none" />
                      <meta name="keywords" content="" />
                      <meta property="og:title" content="Спасибо" />
                      <meta
                        property="og:description"
                        content="Спасибо за заявку, мы свяжемся с Вами в ближайшее время!"
                      />
                    </Helmet>
                  </Thanks>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
          </Route>
          <Route
            path="/ads_game"
            element={
              <Referal
                useOnScreen={useOnScreen}
                setOpenPopup={setOpenPopupForm}
              >
                <Helmet>
                  <meta
                    name="description"
                    content="Активные рефералы для любых игр-кликеров (тапалок). БЕЗ БОТОВ. ЖИВЫЕ
            рефералы с разными уровнями."
                  />
                  <title>
                    Активные рефералы для любых игр-кликеров (тапалок). БЕЗ
                    БОТОВ. ЖИВЫЕ рефералы с разными уровнями.
                  </title>
                  <link rel="canonical" href="https://popov.media/" />
                  <meta name="robots" content="index,follow" />
                  <meta name="keywords" content="" />
                  <meta
                    property="og:title"
                    content="Активные рефералы для любых игр-кликеров (тапалок). БЕЗ БОТОВ. ЖИВЫЕ
            рефералы с разными уровнями."
                  />
                  <meta
                    property="og:description"
                    content="Активные рефералы для любых игр-кликеров (тапалок). БЕЗ БОТОВ. ЖИВЫЕ
            рефералы с разными уровнями."
                  />
                </Helmet>
              </Referal>
            }
          />
          <Route
            status={404}
            path="/404"
            element={
              <ErrorPage>
                <Helmet>
                  <meta
                    name="description"
                    content="Ошибка 404. К сожалению такой страницы не существует. Возможно она была перемещена, или Вы просто неверно указали адрес страницы."
                  />
                  <title>Ошибка 404</title>
                  <link rel="canonical" href="https://popov.media/" />
                  <meta name="robots" content="noindex" />
                  <meta name="keywords" content="" />
                  <meta property="og:title" content="Ошибка 404" />
                  <meta
                    property="og:description"
                    content="Ошибка 404. К сожалению такой страницы не существует. Возможно она была перемещена, или Вы просто неверно указали адрес страницы."
                  />
                </Helmet>
              </ErrorPage>
            }
          />
          <Route
            path="/bitrix24"
            element={
              <>
                <Header />
                <Main
                  class="main__bitrix"
                  setOpenPopup={setOpenPopupForm}
                  title="Внедрение и настройка Битрикс24"
                  subtitle="Настроим CRM для отдела продаж любого размера, реализуем интеграцию Битрикс24 с вашей ERP, обучим сотрудников и будем сопровождать портал."
                  button="ОСТАВИТЬ ЗАЯВКУ"
                />
                <section className="how">
                  <h2 className="section__title">
                    Как мы проводим{" "}
                    <span className="section__title-span">внедрение</span>
                  </h2>
                  <div className="how__box">
                    <div className="how__container">
                      <p className="how__sutitle">
                        1. Аналитика бизнес-процессов
                      </p>
                      <p className="how__text">
                        Оперативно проводим анализ ключевых показателей
                        компании, выявляем точки роста для бизнеса, устраняем
                        базовыми навыками работы с Битрикс24.
                      </p>
                    </div>
                    <div className="how__container">
                      <p className="how__sutitle">2. Настройка и доработка</p>
                      <p className="how__text">
                        Подбираем и производим необходимые настройки и
                        дорабатываем Битрикс24 под индивидуальные потребности
                        базовыми навыками работы с Битрикс24.
                      </p>
                    </div>
                    <div className="how__container">
                      <p className="how__sutitle">
                        3. Интеграция с внешними сервисами
                      </p>
                      <p className="how__text">
                        Интегрируем Битрикс24 с продуктами 1С, сайтом и
                        магазином, почтой, телефонией, соцсетями, а также
                        сервисами аналитики и лидогенерации.
                      </p>
                    </div>
                    <div className="how__container">
                      <p className="how__sutitle">4. Обучение персонала</p>
                      <p className="how__text">
                        Проводим онлайн обучение сотрудников разного уровня
                        подготовки – как новичков, так и тех, кто уже обладает
                        базовыми навыками работы с Битрикс24.
                      </p>
                    </div>
                    <div className="how__container">
                      <p className="how__sutitle">
                        5. Техподдержка и сопровождение
                      </p>
                      <p className="how__text">
                        Наша горячая линия техподдержки по Битрикс24 поможет
                        решить любой вопрос или увеличить эффективность работы с
                        корпоративным порталом.
                      </p>
                    </div>
                  </div>
                </section>
                <section className="tarif">
                  <p className="section__subtitle">
                    Предложение для новых клиентов
                  </p>
                  <h2 className="section__title">
                    Пакетное внедрение{" "}
                    <span className="section__title-span">Битрикс24</span>
                  </h2>
                  <p className="section__text">
                    Пакетное внедрение — это многократно проверенный на практике
                    эффективный метод, который наилучшим образом соответствует
                    потребностям каждого отдельного предприятия.
                  </p>
                  <p className="section__text">
                    Вы быстро получаете уже установленный и настроенный
                    Битрикс24, полностью готовый к использованию, а также
                    подробное руководство, разработанное нами для облегчения
                    работы с системой.
                  </p>
                  <div className="tarif__container">
                    <div className="tarif__box">
                      <h3 className="tarif__title">Базовый пакет</h3>
                      <p className="tarif__price">1990 &#8381;/мес.</p>
                      <ul className="tarif__about">
                        <li>
                          Приглашение персонала численностью до 10 человек,
                          создание одной воронки (карточек, лидов), определение
                          до 10 полей в карточке.
                        </li>
                        <li>
                          Настройка организационной структуры и прав доступа
                          пользователей.
                        </li>
                        <li>
                          Автоматическое направление заявок в систему Битрикс24
                          CRM.
                        </li>
                        <li>
                          Интеграция сервиса обратного звонка, чата и формы
                          обратной связи через виджет Битрикс24 на одном сайте
                          компании.
                        </li>
                        <li>
                          Настройка трёх отчётов CRM для мониторинга основных
                          показателей.
                        </li>
                        <li>Организация одного часа обучения РОПа.</li>
                      </ul>
                      <button
                        className="tarif__button"
                        onClick={() => setOpenPopupForm(true)}
                      >
                        Заказать
                      </button>
                    </div>
                    <div className="tarif__box">
                      <h3 className="tarif__title">Стандартный пакет</h3>
                      <p className="tarif__price">5590 &#8381;/мес.</p>
                      <ul className="tarif__about">
                        <li>
                          Настройка двух направлений воронки продаж: этапы
                          сделок, карточки сделок, автоматизация воронок (до 10
                          роботов), триггеры изменения этапов, шаблоны
                          уведомлений.
                        </li>
                        <li>
                          Организация одного часа группового обучения менеджеров
                          по продажам работе с CRM Битрикс24 под руководством
                          консультанта.
                        </li>
                        <li>
                          Детальная настройка прав доступа к CRM Битрикс24 (до
                          четырёх ролей).
                        </li>
                        <li>
                          Подключение каналов связи с клиентами: телефон,
                          мессенджеры, электронная почта (до трёх адресов).
                        </li>
                        <li>
                          Проведение пяти интервью с ключевыми сотрудниками
                          компании на разных стадиях реализации проекта.
                        </li>
                        <li>
                          Настройка воронки потенциальных клиентов: этапы,
                          карточка, автоматизация воронок (до 10 роботов),
                          шаблоны уведомлений.
                        </li>
                        <li>
                          Настройка трёх отчётов CRM для мониторинга основных
                          показателей.
                        </li>
                        <li>Организация одного часа обучения РОПа.</li>
                      </ul>
                      <button
                        className="tarif__button"
                        onClick={() => setOpenPopupForm(true)}
                      >
                        Заказать
                      </button>
                    </div>
                    <div className="tarif__box">
                      <h3 className="tarif__title">Максимальный пакет</h3>
                      <p className="tarif__price">11190 &#8381;/мес.</p>
                      <ul className="tarif__about">
                        <li>
                          Возможное значительное количество изменений; переход
                          на систему, написанную самостоятельно, или существенно
                          доработанную; обширная интеграция с другими системами
                          и сервисами.
                        </li>
                        <li>Организация встреч и сбор требований.</li>
                        <li>
                          Разработка системы и составление технического задания.
                        </li>
                        <li>Создание документации и инструкций.</li>
                        <li>
                          Тестирование и запуск Битрикс24 на рабочих серверах.
                        </li>
                        <li>
                          Интеграция Битрикс24 с внешними системами и сервисами
                          (ERP, телефония, мессенджеры и другие).
                        </li>
                        <li>
                          Обучение пользователей работе с Битрикс24 в
                          соответствии с их ролями.
                        </li>
                        <li>
                          Поддержка и мониторинг эффективности работы в
                          Битрикс24.
                        </li>
                      </ul>
                      <button
                        className="tarif__button"
                        onClick={() => setOpenPopupForm(true)}
                      >
                        Заказать
                      </button>
                    </div>
                  </div>
                </section>
              </>
            }
          />
          <Route
            path="*"
            element={<Navigate to="/404" replace status={404} />}
          />
        </Routes>
        <Footer />
        <Popup onClose={onClose} isOpen={isOpenPopupForm} name="form">
          <Form
            setThanks={setThanks}
            onClose={onClose}
            formClass="popup__form"
            id="formPopup"
            class="form__box form__box_white"
          >
            <h3 className="form__title form__title_white popup__title">
              {location.pathname === "/bitrix24"
                ? "Оставьте заявку на внедрение и настройку Bitrix24 прямо сейчас и мы свяжемся с Вами через несколько минут!"
                : "не знаете какая реклама подойдет именно вам? Не беда, мы знаем и проконсультируем!"}
            </h3>
          </Form>
        </Popup>
        <Popup onClose={onClose} isOpen={isOpenPopupArticle} name="article">
          <div className="article__header-box">
            <a
              href="https://vk.com/blogpopov"
              target="_blank"
              rel="noreferrer"
              className="article__owner_link"
            >
              <picture>
                <source srcSet={ownerwebp} type="image/webp" />
                <source srcSet={owneravif} type="image/avif" />
                <source srcSet={owner} type="image/png" />
                <img
                  className="article__owner_img"
                  src={owner}
                  alt="Владимир Попов"
                />
              </picture>
            </a>
            <div className="article__container">
              <a
                href="https://vk.com/blogpopov"
                target="_blank"
                rel="noreferrer"
                className="article__owner_name"
              >
                Владимир Попов
              </a>
              <p className="article__date">{isDate}</p>
            </div>
          </div>
          <h3 className="section__title">{isTitle}</h3>
          {isArticle.map((a) =>
            a.type === "img" ? (
              <picture>
                <source srcSet={a.contentwebp} type="image/webp" />
                <source srcSet={a.contentavif} type="image/avif" />
                <source srcSet={a.content} type="image/png" />
                <img
                  className="article__img"
                  src={a.content}
                  alt="Картинка статьи"
                />
              </picture>
            ) : a.type === "text" ? (
              <p className={`article__${a.type}`}>{a.content}</p>
            ) : (
              <a className={`article__${a.type}`} href={a.link}>
                {a.content}
              </a>
            )
          )}
        </Popup>
        <Popup onClose={onClose} isOpen={isOpenPopupImg} name="img">
          {isOpenPopupImg ? (
            <picture>
              <source srcSet={isPopupImg.webp} type="image/webp" />
              <source srcSet={isPopupImg.avif} type="image/avif" />
              <source srcSet={isPopupImg.src} type="image/png" />
              <img
                className="popup__img"
                src={isPopupImg.src}
                alt="Сертификат"
              />
            </picture>
          ) : (
            <></>
          )}
        </Popup>
      </Suspense>
    </div>
  );
}

export default App;
